import { SearchResultColumns } from '../interfaces/menu';

export const searchResultsDefined: SearchResultColumns[] = [
  {
    displayName: 'Company',
    dataFieldName: 'ACCOUNTCOMPANY',
    isSortable: true,
    hasFilter: false,
    showFilter: false,
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.company',
  },
  {
    displayName: 'First Name',
    dataFieldName: 'FIRST_NAME',
    isSortable: true,
    hasFilter: false,
    showFilter: false,
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.firstName',
  },
  {
    displayName: 'Last Name',
    dataFieldName: 'LAST_NAME',
    isSortable: true,
    hasFilter: false,
    showFilter: false,
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.lastName',
  },
  {
    displayName: 'Email',
    dataFieldName: 'EMAIL',
    isSortable: true,
    hasFilter: false,
    showFilter: false,
    dateFilter: false,
    multiLanguageKey: 'shared.common.email',
  },
  {
    displayName: 'Phone',
    dataFieldName: 'PHONE',
    fieldType: 'phone',
    isSortable: true,
    hasFilter: false,
    showFilter: false,
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.phone',
  },
  {
    displayName: 'State',
    dataFieldName: 'STATEPROVINCE',
    isSortable: true,
    hasFilter: false,
    showFilter: false,
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.state',
  },
  {
    displayName: 'Branch Name',
    dataFieldName: 'BANK_BRANCH_BRANCH_NAME',
    isSortable: true,
    hasFilter: false,
    showFilter: false,
    dateFilter: false,
    multiLanguageKey: 'shared.branchName',
  },
  {
    displayName: 'Campaign Name',
    dataFieldName: 'CampaignName',
    isSortable: true,
    hasFilter: false,
    showFilter: false,
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.campaignName',
  },
  {
    displayName: 'Created Date',
    dataFieldName: 'LEAD_CREATED_DATE',
    isSortable: true,
    fieldType: 'date',
    hasFilter: false,
    showFilter: false,
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.createdDate',
  },
  {
    displayName: 'Sales Stage',
    dataFieldName: 'SALES_STAGE',
    isSortable: true,
    hasFilter: false,
    showFilter: false,
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.salesStage',
  },
  {
    displayName: 'Referrer Name',
    dataFieldName: 'BRANCH_EMP_NAME',
    isSortable: true,
    hasFilter: false,
    showFilter: false,
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.referrerName',
  },
  {
    displayName: 'Processing Volume',
    dataFieldName: 'Revenue',
    isSortable: false,
    hasFilter: false,
    showFilter: false,
    fieldType: 'currency',
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.processingVolume',
  },
  {
    displayName: 'Action',
    dataFieldName: 'Action',
    hasFilter: false,
    showFilter: false,
    multiLanguageKey: 'referralTableColumnName.action',
    isSortable: false,
    dateFilter: false,
  },
];
export const leadsearchResultsDefined: SearchResultColumns[] = [
  {
    displayName: 'Business Name',
    dataFieldName: 'company',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'leadTableColumnName.businessname',
  },
  {
    displayName: 'First Name',
    dataFieldName: 'firstName',
    hasFilter: true,
    showFilter: false,
    isSortable: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.firstName',
  },
  {
    displayName: 'Last Name',
    dataFieldName: 'lastName',
    hasFilter: true,
    showFilter: false,
    isSortable: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.lastName',
  },
  {
    displayName: 'Email',
    dataFieldName: 'email',
    hasFilter: true,
    showFilter: false,
    isSortable: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'shared.common.email',
  },
  {
    displayName: 'Phone Number',
    dataFieldName: 'phone',
    isSortable: true,
    showFilter: false,
    hasFilter: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'opportunity.phonenumber',
  },
  {
    displayName: 'Catalyst Number',
    dataFieldName: 'entityTwilio',
    isSortable: true,
    showFilter: false,
    hasFilter: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'catalyst.number',
  },
  {
    displayName: 'Last Contacted',
    dataFieldName: 'lastContactedDate',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'date',
    dateFilter: true,
    multiLanguageKey: 'grid.filter.lastContactedDate',
  },
  {
    displayName: 'Lead Status',
    dataFieldName: 'leadStatus',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'multi',
    dateFilter: false,
    multiLanguageKey: 'leadTableColumnName.leadStatus',
  },
  {
    displayName: 'Owner Name',
    dataFieldName: 'ownerName',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'leadTableColumnName.ownerName',
  },
  {
    displayName: 'Lead Rating',
    dataFieldName: 'leadRating',
    fieldType: 'text',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    dateFilter: false,
    multiLanguageKey: 'shared.common.lead.rating',
  },
];
export const refineSearchTagClicked = [
  {
    displayName: 'Business Name',
    dataFieldName: 'company',
    selected: false,
    multiLanguageKey: 'leadTableColumnName.businessname',
    value: '',
  },{
    displayName: 'MID',
    dataFieldName: 'merchantMid',
    selected: false,
    multiLanguageKey: 'opportunities.view.mid',
    value: '',
  },
  {
    displayName: 'First Name',
    dataFieldName: 'firstName',
    selected: false,
    multiLanguageKey: 'referralTableColumnName.firstName',
    value: '',
  },
  {
    displayName: 'Last Name',
    dataFieldName: 'lastName',
    selected: false,
    multiLanguageKey: 'referralTableColumnName.lastName',
    value: '',
  },
  {
    displayName: 'Email',
    dataFieldName: 'email',
    selected: false,
    multiLanguageKey: 'shared.common.email',
    value: '',
  },
  {
    displayName: 'Phone Number',
    dataFieldName: 'phone',
    selected: false,
    multiLanguageKey: 'opportunity.phonenumber',
    value: '',
  },
  {
    displayName: 'Catalyst Number',
    dataFieldName: 'entityTwilio',
    selected: false,
    multiLanguageKey: 'catalyst.number',
    value: '',
  },
];
export const opportunitySearchResultsDefined: SearchResultColumns[] = [
  {
    displayName: 'Business Name',
    dataFieldName: 'legalName',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'leadTableColumnName.businessname',
  },
  {
    displayName: 'First Name',
    dataFieldName: 'firstName',
    hasFilter: true,
    showFilter: false,
    isSortable: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.firstName',
  },
  {
    displayName: 'Last Name',
    dataFieldName: 'lastName',
    hasFilter: true,
    showFilter: false,
    isSortable: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.lastName',
  },
  {
    displayName: 'Email',
    dataFieldName: 'email',
    hasFilter: true,
    showFilter: false,
    isSortable: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'shared.common.email',
  },
  {
    displayName: 'Phone Number',
    dataFieldName: 'phone',
    isSortable: true,
    showFilter: false,
    hasFilter: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'opportunity.phonenumber',
  },
  {
    displayName: 'Catalyst Number',
    dataFieldName: 'entityTwilio',
    isSortable: true,
    showFilter: false,
    hasFilter: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'catalyst.number',
  },
  {
    displayName: 'Sales Stage',
    dataFieldName: 'salesStage',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'agenda.pipeline.salesstage',
  },
  {
    displayName: 'Owner Name',
    dataFieldName: 'ownerName',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'leadTableColumnName.ownerName',
  },
  {
    displayName: 'Close Date',
    dataFieldName: 'closeDate',
    fieldType: 'date',
    hasFilter: true,
    showFilter: false,
    isSortable: true,
    dateFilter: true,
    multiLanguageKey: 'agenda.pipeline.pastclosedate.closedate',
  },
];


export const accountSearchResultsDefined: SearchResultColumns[] = [
  {
    displayName: 'Business Name',
    dataFieldName: 'accountName',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'leadTableColumnName.businessname',
  },
  {
    displayName: 'MID',
    dataFieldName: 'merchantMid',
    hasFilter: true,
    showFilter: false,
    isSortable: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'opportunities.view.mid',
  },
  {
    displayName: 'First Name',
    dataFieldName: 'firstName',
    hasFilter: true,
    showFilter: false,
    isSortable: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.firstName',
  },
  {
    displayName: 'Last Name',
    dataFieldName: 'lastName',
    hasFilter: true,
    showFilter: false,
    isSortable: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'referralTableColumnName.lastName',
  },
  {
    displayName: 'Email',
    dataFieldName: 'email',
    hasFilter: true,
    showFilter: false,
    isSortable: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'shared.common.email',
  },
  {
    displayName: 'Phone Number',
    dataFieldName: 'phone',
    isSortable: true,
    showFilter: false,
    hasFilter: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'opportunity.phonenumber',
  },
  {
    displayName: 'Last Contacted',
    dataFieldName: 'lastContactedDate',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'date',
    dateFilter: true,
    multiLanguageKey: 'grid.filter.lastContactedDate',
  },
  {
    displayName: 'Age (Months)',
    dataFieldName: 'age',
    isSortable: true,
    hasFilter: false,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'shared.age.in.months',
  },
];

export const  moaListSearchResultsDefined: SearchResultColumns[] = [
  {
    displayName: 'List Name',
    dataFieldName: 'listName',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'yourlistTableColumnName.listName',
  },
  {
    displayName: 'Description',
    dataFieldName: 'description',
    hasFilter: false,
    showFilter: false,
    isSortable: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'yourlistTableColumnName.description',
  },
  {
    displayName: 'Total Prospects',
    dataFieldName: 'totalProspects',
    hasFilter: true,
    showFilter: false,
    isSortable: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'yourlistTableColumnName.totalProspects',
  },
  {
    displayName: 'Created By',
    dataFieldName: 'createdBy',
    hasFilter: true,
    showFilter: false,
    isSortable: true,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'yourlistTableColumnName.createdBy',
  },
  {
    displayName: 'Created On',
    dataFieldName: 'createdOn',
    hasFilter: true,
    showFilter: false,
    isSortable: true,
    fieldType: 'date',
    dateFilter: true,
    multiLanguageKey: 'yourlistTableColumnName.createdOn',
  },
  {
    displayName: 'Last Status Change',
    dataFieldName: 'lastStatusChange',
    isSortable: true,
    showFilter: false,
    hasFilter: true,
    fieldType: 'date',
    dateFilter: true,
    multiLanguageKey: 'yourlistTableColumnName.lastStatusChange',
  },
  {
    displayName: 'Avg Processing Volume',
    dataFieldName: 'avgProcessingVolume',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'yourlistTableColumnName.avgProcessingVolume',
  },
  {
    displayName: 'Admins',
    dataFieldName: 'admins',
    isSortable: false,
    hasFilter: false,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'yourlistTableColumnName.admins',
  },
  {
    displayName: 'Contributors',
    dataFieldName: 'contributors',
    isSortable: false,
    hasFilter: false,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'yourlistTableColumnName.contributors',
  }
];

export const  propectsSearchResultsDefined: SearchResultColumns[] = [
  {
    displayName: 'Business Name',
    dataFieldName: 'businessName',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'prospectTableColumnName.businessName',
  },
  {
    displayName: 'Stage',
    dataFieldName: 'stage',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'prospectTableColumnName.stage',
  },
  {
    displayName: 'Status',
    dataFieldName: 'status',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'multi',
    dateFilter: false,
    multiLanguageKey: 'prospectTableColumnName.status',
  },
  {
    displayName: 'Branch Name',
    dataFieldName: 'branchName',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'prospectTableColumnName.branchName',
  },
  {
    displayName: 'Date Added',
    dataFieldName: 'dateCreated',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'date',
    dateFilter: true,
    multiLanguageKey: 'grid.filter.dateCreated',
  },
  {
    displayName: 'Processing Volume',
    dataFieldName: 'processingVolume',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'prospectTableColumnName.processingVolume',
  },
  {
    displayName: 'Projected Revenue',
    dataFieldName: 'projectedRevenue',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'prospectTableColumnName.projectedRevenue',
  },
  {
    displayName: 'Processor',
    dataFieldName: 'processor',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'prospectTableColumnName.processor',
  },
  {
    displayName: 'In # of Lists',
    dataFieldName: 'numofLists',
    isSortable: true,
    hasFilter: true,
    showFilter: false,
    fieldType: 'text',
    dateFilter: false,
    multiLanguageKey: 'prospectTableColumnName.inOfLists',
  },
];




